html,
body {
    width: 100%;
    height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fadeOut {
    -webkit-animation: fademeout .5s;
            animation: fademeout .5s;
}

@-webkit-keyframes fademeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes fademeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.App {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@font-face {
  font-family: whitepinky;
  src: url(/static/media/WhitePinky.aae2b419.otf) format("opentype");
}

@media only screen and (max-width: 823px) {
  .maincomp {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow-y: auto;
  }
  .maincomp .sections-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    overflow-y: auto;
  }
  .maincomp .sections-container .sections {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }
  .maincomp .sections-container .sections .section {
    width: 100%;
    height: 60px;
    transition: all .3s;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .maincomp .sections-container .sections .section .letters {
    display: -webkit-flex;
    display: flex;
  }
  .maincomp .sections-container .sections .section .sectionbody {
    display: none !important;
  }
  .maincomp .sections-container .rightstats {
    padding: 10px 20px 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }
  .maincomp .sections-container .rightstats img {
    max-width: 600px;
  }
  .maincomp .sections-container .rightstats .maintitle {
    font-family: whitepinky;
    font-size: 2em;
    text-align: center;
    margin-bottom: 10px;
  }
  .maincomp .sections-container .rightstats .result,
  .maincomp .sections-container .rightstats .tries {
    text-align: center;
    font-weight: 700;
    font-size: 2em;
    font-family: monospace;
    margin: 0px;
  }
  .maincomp .sections-container .rightstats .tries {
    margin-bottom: 40px;
  }
  .maincomp .sections-container .rightstats .credit {
    position: absolute;
    bottom: -10px;
    font-family: monospace;
    font-size: small;
    text-align: center;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  .maincomp {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .maincomp .sections-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .maincomp .sections-container .sections {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  .maincomp .sections-container .sections .section {
    width: 50px;
    height: 100%;
    padding: 0 20px;
    transition: all .3s;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .maincomp .rightstats {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 50px 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .maincomp .rightstats img {
    max-width: 600px;
  }
  .maincomp .rightstats .maintitle {
    font-family: whitepinky;
    font-size: 3em;
    text-align: center;
  }
  .maincomp .rightstats .result,
  .maincomp .rightstats .tries {
    text-align: center;
    font-weight: 700;
    font-size: 2em;
    font-family: monospace;
    margin: 0px;
  }
  .maincomp .rightstats .credit {
    position: absolute;
    bottom: 0px;
    font-family: monospace;
    font-size: small;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  .maincomp {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .maincomp .sections-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .maincomp .sections-container .sections {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  .maincomp .sections-container .sections .section {
    width: 70px;
    height: 100%;
    padding: 0 20px;
    transition: all .3s;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .maincomp .rightstats {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 50px 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .maincomp .rightstats img {
    max-width: 600px;
  }
  .maincomp .rightstats .maintitle {
    font-family: whitepinky;
    font-size: 3em;
    text-align: center;
  }
  .maincomp .rightstats .result,
  .maincomp .rightstats .tries {
    text-align: center;
    font-weight: 700;
    font-size: 2em;
    font-family: monospace;
    margin: 0px;
  }
  .maincomp .rightstats .credit {
    position: absolute;
    bottom: 0px;
    font-family: monospace;
    font-size: small;
  }
}

@media only screen and (min-width: 1440px) {
  .maincomp {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .maincomp .sections-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .maincomp .sections-container .sections {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  .maincomp .sections-container .sections .section {
    width: 80px;
    height: 100%;
    padding: 0 20px;
    transition: all .3s;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .maincomp .rightstats {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 50px 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .maincomp .rightstats img {
    max-width: 600px;
  }
  .maincomp .rightstats .maintitle {
    font-family: whitepinky;
    font-size: 3em;
    text-align: center;
  }
  .maincomp .rightstats .result,
  .maincomp .rightstats .tries {
    text-align: center;
    font-weight: 700;
    font-size: 2em;
    font-family: monospace;
    margin: 0px;
  }
  .maincomp .rightstats .credit {
    position: absolute;
    bottom: 0px;
    font-family: monospace;
    font-size: small;
  }
}

.noscroll {
  overflow: hidden !important;
}
/*# sourceMappingURL=main.css.map */
@media only screen and (max-width: 823px) {
  .section .letters .letter {
    color: white;
    font-size: 2em;
    margin: 0px;
    text-align: center;
    font-weight: 700;
    transition: all .25s;
    transition-delay: .25s;
  }
  .section .sectionbody {
    position: absolute;
    left: 0px;
    bottom: 0px;
    opacity: 0;
    width: 100%;
    text-align: center;
  }
  .section .sectionbody .numberofq {
    color: white;
    font-size: 2em;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  .section .letters .letter {
    color: white;
    font-size: 2em;
    margin: 0px;
    text-align: center;
    font-weight: 700;
    transition: all .25s;
    transition-delay: .25s;
  }
  .section .sectionbody {
    position: absolute;
    left: 0px;
    bottom: 0px;
    opacity: 0;
    width: 100%;
    text-align: center;
  }
  .section .sectionbody .numberofq {
    color: white;
    font-size: 2em;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  .section {
    position: absolute;
  }
  .section .letters .letter {
    color: white;
    font-size: 3em;
    margin: 0px;
    text-align: center;
    font-weight: 700;
    transition: all .25s;
    transition-delay: .25s;
  }
  .section .sectionbody {
    position: absolute;
    left: 0px;
    bottom: 0px;
    opacity: 0;
    width: 100%;
    text-align: center;
  }
  .section .sectionbody .numberofq {
    color: white;
    font-size: 2em;
  }
}

@media only screen and (min-width: 1440px) {
  .section {
    position: absolute;
  }
  .section .letters .letter {
    color: white;
    font-size: 4em;
    margin: 0px;
    text-align: center;
    font-weight: 700;
    transition: all .25s;
    transition-delay: .25s;
  }
  .section .sectionbody {
    position: absolute;
    left: 0px;
    bottom: 0px;
    opacity: 0;
    width: 100%;
    text-align: center;
  }
  .section .sectionbody .numberofq {
    color: white;
    font-size: 2em;
  }
}

.section:hover .sectionbody {
  opacity: 1 !important;
}
/*# sourceMappingURL=tile.css.map */
@media only screen and (max-width: 823px) {
  #actives {
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    transition: all .3s;
    padding: 20px;
    position: absolute;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 100%;
    background: white;
  }
  #actives .backarrow {
    max-width: 20px;
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 20px;
    cursor: pointer;
  }
  #actives .title {
    color: white;
    margin: 0px;
    margin-left: 40px;
    font-size: 1.4em;
  }
  #actives .subtitle {
    color: white;
    margin: 0px;
    font-size: 14px;
    opacity: .8;
    margin-left: 40px;
  }
  #actives .questions {
    margin-top: 20px;
    height: 100%;
  }
  #actives .questions .question {
    background: white;
    border-radius: 2px;
    padding: 10px 0;
    height: calc(100% - 90px);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow-y: auto;
  }
  #actives .questions .question .question-inner-container {
    width: calc(100% - 20px);
    height: 100%;
  }
  #actives .questions .question .question-title {
    margin: 0px;
    font-size: 1em;
    font-weight: 700;
  }
  #actives .questions .question .question-content {
    font-size: .9em;
  }
  #actives .questions .question .question-options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  #actives .questions .question .question-options label {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 12px 5px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }
  #actives .questions .question .actions {
    height: 40px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  #actives .questions .question .actions .skip,
  #actives .questions .question .actions .check,
  #actives .questions .question .actions .nextq {
    background: none;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    height: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin: 0 5px;
  }
  #actives .questions .question .actions .nextq {
    color: #333;
  }
  #actives .questions .question .actions .skip {
    color: #F44336;
  }
  #actives .questions .question .actions .skip:disabled {
    color: grey !important;
  }
  #actives .wrong-msg {
    background: #faebeb;
    padding: 20px;
    border-radius: 3px;
  }
  #actives .success-msg {
    background: #e9f7f1;
    padding: 20px;
    border-radius: 3px;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  #actives {
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    transition: all .3s;
    padding: 20px;
    position: absolute;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 100%;
    background: white;
  }
  #actives .backarrow {
    max-width: 20px;
    display: inline-block;
    position: absolute;
    top: 40px;
    left: 20px;
    cursor: pointer;
  }
  #actives .title {
    color: white;
    margin: 0px;
    margin-left: 40px;
  }
  #actives .subtitle {
    color: white;
    margin: 0px;
    font-size: 14px;
    opacity: .8;
    margin-left: 40px;
  }
  #actives .questions {
    margin-top: 20px;
    height: 100%;
  }
  #actives .questions .question {
    background: white;
    border-radius: 2px;
    padding: 10px 0;
    height: calc(100% - 90px);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow-y: auto;
  }
  #actives .questions .question .question-inner-container {
    width: 60%;
    height: 100%;
  }
  #actives .questions .question .question-title {
    margin: 0px;
    font-size: 1.5em;
    font-weight: 700;
  }
  #actives .questions .question .question-options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  #actives .questions .question .question-options label {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 12px 5px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }
  #actives .questions .question .actions {
    height: 100px;
    width: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: absolute;
    bottom: 20px;
    right: 20px;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  #actives .questions .question .actions .skip,
  #actives .questions .question .actions .check,
  #actives .questions .question .actions .nextq {
    background: none;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    height: 100%;
  }
  #actives .questions .question .actions .nextq {
    color: #333;
    height: 50% !important;
  }
  #actives .questions .question .actions .skip {
    color: #F44336;
    margin-top: 10px;
  }
  #actives .questions .question .actions .skip:disabled {
    color: grey !important;
  }
  #actives .wrong-msg {
    background: #faebeb;
    padding: 20px;
    border-radius: 3px;
  }
  #actives .success-msg {
    background: #e9f7f1;
    padding: 20px;
    border-radius: 3px;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  #actives {
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    transition: all .3s;
    padding: 20px;
    position: absolute;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 100%;
    background: white;
  }
  #actives .backarrow {
    max-width: 20px;
    display: inline-block;
    position: absolute;
    top: 40px;
    left: 20px;
    cursor: pointer;
  }
  #actives .title {
    color: white;
    margin: 0px;
    margin-left: 40px;
  }
  #actives .subtitle {
    color: white;
    margin: 0px;
    font-size: 14px;
    opacity: .8;
    margin-left: 40px;
  }
  #actives .questions {
    margin-top: 20px;
    height: 100%;
  }
  #actives .questions .question {
    background: white;
    border-radius: 2px;
    padding: 10px 0;
    height: calc(100% - 90px);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow-y: auto;
  }
  #actives .questions .question .question-inner-container {
    width: 60%;
    height: 100%;
  }
  #actives .questions .question .question-title {
    margin: 0px;
    font-size: 1.5em;
    font-weight: 700;
  }
  #actives .questions .question .question-options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  #actives .questions .question .question-options label {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 12px 5px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }
  #actives .questions .question .actions {
    height: 100px;
    width: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    position: absolute;
    bottom: 20px;
    right: 20px;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  #actives .questions .question .actions .skip,
  #actives .questions .question .actions .check,
  #actives .questions .question .actions .nextq {
    background: none;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    height: 100%;
  }
  #actives .questions .question .actions .nextq {
    color: #333;
    height: 50% !important;
  }
  #actives .questions .question .actions .skip {
    color: #F44336;
    margin-top: 10px;
  }
  #actives .questions .question .actions .skip:disabled {
    color: grey !important;
  }
  #actives .wrong-msg {
    background: #faebeb;
    padding: 20px;
    border-radius: 3px;
  }
  #actives .success-msg {
    background: #e9f7f1;
    padding: 20px;
    border-radius: 3px;
  }
}

@media only screen and (min-width: 1440px) {
  #actives {
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    transition: all .3s;
    padding: 20px;
    position: absolute;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 100%;
    background: white;
  }
  #actives .backarrow {
    max-width: 20px;
    display: inline-block;
    position: absolute;
    top: 40px;
    left: 20px;
    cursor: pointer;
  }
  #actives .title {
    color: white;
    margin: 0px;
    margin-left: 40px;
  }
  #actives .subtitle {
    color: white;
    margin: 0px;
    font-size: 14px;
    opacity: .8;
    margin-left: 40px;
  }
  #actives .questions {
    margin-top: 20px;
    height: 100%;
  }
  #actives .questions .question {
    background: white;
    border-radius: 2px;
    padding: 40px 0;
    height: calc(100% - 160px);
  }
  #actives .questions .question .question-inner-container {
    width: 60%;
    height: 100%;
    padding: 0 20%;
    position: relative;
  }
  #actives .questions .question .question-title {
    margin: 0px;
    font-size: 1.5em;
    font-weight: 700;
  }
  #actives .questions .question .question-options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  #actives .questions .question .question-options label {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 12px 5px;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }
  #actives .questions .question .actions {
    height: 40px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-top: 70px;
  }
  #actives .questions .question .actions .skip,
  #actives .questions .question .actions .check,
  #actives .questions .question .actions .nextq {
    margin-right: 20px;
    background: none;
    border: 1px solid #cccccc;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  #actives .questions .question .actions .nextq {
    color: #333;
  }
  #actives .questions .question .actions .skip {
    color: #F44336;
  }
  #actives .questions .question .actions .skip:disabled {
    color: grey !important;
  }
  #actives .wrong-msg {
    background: #faebeb;
    padding: 20px;
    border-radius: 3px;
  }
  #actives .success-msg {
    background: #e9f7f1;
    padding: 20px;
    border-radius: 3px;
  }
}

.openexam {
  -webkit-animation: slidein 1s ease-in-out 0s 1 normal forwards;
          animation: slidein 1s ease-in-out 0s 1 normal forwards;
}

.slideout {
  -webkit-animation: slideright 1s ease-in-out 0s 1 normal forwards;
          animation: slideright 1s ease-in-out 0s 1 normal forwards;
}

@-webkit-keyframes slidein {
  0% {
    left: -100%;
  }
  100% {
    left: 0px;
  }
}

@keyframes slidein {
  0% {
    left: -100%;
  }
  100% {
    left: 0px;
  }
}

@-webkit-keyframes slideright {
  0% {
    left: 0px;
  }
  100% {
    left: 100%;
  }
}

@keyframes slideright {
  0% {
    left: 0px;
  }
  100% {
    left: 100%;
  }
}
/*# sourceMappingURL=exam.css.map */
